// external
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// components
import {
  RelationshipDetails,
  RelationshipsArchivedList,
  RelationshipsList,
} from 'components';

// store

// hooks
import {
  useLocalization,
  useMemberships,
  useMixpanelEvents,
  useMobileMediaQuery,
} from 'hooks';

import { useRelationships } from '@guider-global/front-end-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useBaseLanguage,
  useSettings,
} from '@guider-global/sanity-hooks';

export const RelationshipsPage: React.FC = () => {
  const [isNewRelationshipLoading, setIsNewRelationshipLoading] =
    useState(false);
  const navigate = useNavigate();
  const { relationshipId } = useParams();
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { settings } = useSettings({
    localeCode,
  });
  useMixpanelEvents({ navigationEventName: 'Relationships' });

  const { baseLanguage } = useBaseLanguage({ localeCode });
  const { relationships, isLoadingRelationships } = useRelationships({});

  const activeRelationships = useMemo(
    () =>
      relationships?.filter((relationship) => !relationship.isConcluded) || [],
    [relationships],
  );

  useMemberships({ getSilently: true });

  useEffect(() => {
    setIsNewRelationshipLoading(true);

    const timer = setTimeout(() => {
      setIsNewRelationshipLoading(false);
    });

    return () => clearTimeout(timer);
  }, [relationshipId]);

  const currentRelationship = useMemo(
    () =>
      relationships?.find((relationship) => relationship.id === relationshipId),
    [relationships, relationshipId],
  );

  const currentRelationshipArchived = currentRelationship?.isConcluded;

  const shouldRenavigate =
    !isLoadingRelationships &&
    relationshipId &&
    !currentRelationship &&
    Boolean(relationships);
  useEffect(() => {
    if (shouldRenavigate) {
      navigate('/relationships');
    }
  }, [
    currentRelationship,
    navigate,
    relationshipId,
    shouldRenavigate,
    isLoadingRelationships,
  ]);

  const firstActiveRelationshipId = activeRelationships?.at(0)?.id;
  useEffect(() => {
    if (isLoadingRelationships) return;
    if (relationshipId) return;
    if (isMobile) return;
    if (!firstActiveRelationshipId) return;
    navigate(`./${firstActiveRelationshipId}`);
  }, [
    relationshipId,
    firstActiveRelationshipId,
    isMobile,
    navigate,
    currentRelationship,
    isLoadingRelationships,
  ]);

  const archivedRelationships = useMemo(
    () =>
      relationships?.filter((relationship) => relationship.isConcluded) || [],
    [relationships],
  );

  const renderSidebar = useCallback(() => {
    if (!isMobile || (!relationshipId && relationships?.length !== 0)) {
      return (
        <Box
          sx={{
            position: 'relative',
            zIndex: 11,
            width: isMobile ? '100%' : '260px',
            minWidth: isMobile ? '100%' : '260px',
            backgroundColor: '#F5F5F5',
            p: 3,
            borderRight: `1px solid ${theme.palette.divider}`,
            borderTop: `1px solid ${theme.palette.divider}`,
            overflowY: 'auto',
          }}
          data-cy="relationships-container"
        >
          <Typography
            variant="h6"
            component="h1"
            sx={{ mb: 2 }}
            data-cy="relationships-title"
          >
            {baseLanguage?.relationships?.relationships_list?.title}
          </Typography>
          <RelationshipsList
            relationships={activeRelationships}
            isLoading={isLoadingRelationships}
          />
          <RelationshipsArchivedList
            relationships={archivedRelationships}
            isLoading={isLoadingRelationships}
          />
        </Box>
      );
    }
  }, [
    activeRelationships,
    archivedRelationships,
    baseLanguage?.relationships?.relationships_list?.title,
    isLoadingRelationships,
    isMobile,
    relationshipId,
    relationships?.length,
    theme.palette.divider,
  ]);

  const renderNoRelationships = useCallback(() => {
    if (isLoadingRelationships) return;
    if (currentRelationshipArchived) return;

    if (
      (!isMobile && activeRelationships?.length === 0) ||
      (isMobile && relationships?.length === 0)
    ) {
      return (
        <Box
          sx={{
            width: '100%',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          {isMobile && (
            <Typography variant="h6" component="h1" sx={{ mt: 3, ml: 2 }}>
              {baseLanguage?.relationships?.relationships_list?.title}
            </Typography>
          )}
          <Box
            sx={{
              width: '100%',
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              p: isMobile ? 2 : 0,
            }}
            data-cy="no-relationships-container"
          >
            <Box
              sx={{ width: '280px' }}
              component="img"
              src={buildSanityImageUrl({
                source:
                  settings?.static_media.relationships
                    .empty_state_illustration ?? '',
              })}
              data-cy="no-relationships-container-image"
            />
            <Typography
              variant="h5"
              component="h2"
              sx={{ mt: 3, mb: 0.75 }}
              data-cy="no-relationships-container-title"
            >
              {baseLanguage?.relationships?.no_relationships_state?.title}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 3, textAlign: 'center' }}
              data-cy="no-relationships-container-description"
            >
              {baseLanguage?.relationships?.no_relationships_state?.description}
            </Typography>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                variant="contained"
                onClick={() => navigate('/programs')}
                color="info"
                fullWidth={isMobile}
                sx={{ mr: isMobile ? 0 : 3, mb: isMobile ? 1.5 : 0 }}
                data-cy="no-relationships-container-view-programs-button"
              >
                {baseLanguage?.globals?.programs?.view_programs_button_label}
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate('/learn')}
                color="info"
                fullWidth={isMobile}
                data-cy="no-relationships-container-explore-learining-hub-button"
              >
                {baseLanguage?.globals?.common?.explore_learning_hub}
              </Button>
            </Box>
          </Box>
        </Box>
      );
    }
  }, [
    isLoadingRelationships,
    currentRelationshipArchived,
    isMobile,
    activeRelationships?.length,
    relationships?.length,
    baseLanguage?.relationships?.relationships_list?.title,
    baseLanguage?.relationships?.no_relationships_state?.title,
    baseLanguage?.relationships?.no_relationships_state?.description,
    baseLanguage?.globals?.programs?.view_programs_button_label,
    baseLanguage?.globals?.common?.explore_learning_hub,
    settings?.static_media.relationships.empty_state_illustration,
    navigate,
  ]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        backgroundColor: '#FFFFFF',
        flexGrow: 1,
        maxHeight: { xs: '100vh', md: 'calc(100vh - 64px)' },
      }}
    >
      {renderSidebar()}

      {currentRelationship && !isNewRelationshipLoading && (
        <RelationshipDetails relationship={currentRelationship} />
      )}

      {renderNoRelationships()}
    </Box>
  );
};

export default RelationshipsPage;
